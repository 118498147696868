import { Link } from "react-router-dom";

import "./styles/ShowcaseBox.scss";

const ShowcaseBox = (props) => {
  const projectData = props.projectData;

  return (
    <div className="showcase-container">
      <Link
        className="showcase-title"
        to={"/portfolio/project-details-" + props.type + "-" + props.index}
      >
        {projectData.title}
        <div className="showcase-border_line"></div>
      </Link>
      <h4 className="showcase-description">{projectData.shortDescription}</h4>
      <Link to={"/portfolio/project-details-" + props.type + "-" + props.index}>
        <div className="showcase-image_container">
          <img className="showcase-image" src={projectData.image}></img>
        </div>
      </Link>
    </div>
  );
};

export default ShowcaseBox;
