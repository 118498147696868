import image_1 from "../images/showcase/university/tvf.png";
import image_2 from "../images/showcase/university/black_books.png";
import image_3 from "../images/showcase/university/castle.png";

function getUniversityProjectsData() {
  const UNIVERSITY_PROJECTS = [
    {
      title: "The Vikings Fate",
      url: "https://serwer2204419.home.pl/video/tvf.mp4",
      image: image_1,
      shortDescription: "Final year project (Unity Engine & C#, 2021)",
      description:
        "Final year project with implementation of Advanced AI techniques: \n\n - Flocking, \n - Pathfinding \n - Finite State Machine \n \n The game was entirely created by me using third party graphic assets (i.e. models, interface textures, sounds, music and animations)",
    },
    {
      title: "The Black Books",
      url: "https://serwer2204419.home.pl/video/tbb.mp4",
      image: image_2,
      shortDescription: "Software Engineering (Unreal Engine, 2020)",
      description:
        "In this group project I was responsible for User Interface and Environment Interaction. I used Adobe After Effects to create textures for the interface. \n\n User Interface elements: \n\n - Health Bar and counter \n - Stamina Bar \n - Ammo Bar and counter \n - Crosshair reactions \n - Information about currently eqquiped weapon \n - Grenade counter \n - Main menu \n - Waypoint system \n - Objective functionality \n - Interaction notification \n - Blood effect on the screen \n\n Environment interaction elements: \n\n - Laser room (lasers movement, button interaction) \n - Door interaction/movement \n - Decontamination room interaction \n - Weapon pickup system \n - Lantern lighting effect \n - Bridge interaction/movement \n - Crane interaction/movement \n - Container drop system \n - Turret \n - Windmill movement",
    },
    {
      title: "The Castle",
      url: "https://serwer2204419.home.pl/video/castle.mp4",
      image: image_3,
      shortDescription: "Animation (JavaScript and THREE.js, 2020)",
      description:
        "In this project, my task was to create three-dimensional models using java-script coding with the THREE.js library.",
    },
    {
      title: "Top Down Shooter",
      url: "https://serwer2204419.home.pl/video/castle.mp4",
      image: "",
      shortDescription: "Software Architecture (C++, 2021)",
      description:
        "In this project, my task was to create three-dimensional models using java-script coding language with the THREE.js library. In this project, I also gone beyond expectations and created a procedurally generated forest with trees that are also procedurally generated.",
    },
  ];

  return UNIVERSITY_PROJECTS;
}

export default getUniversityProjectsData;
