import ReactGA from "react-ga4";

import "./styles/Contact.scss";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const Contact = () => {
  ReactGA.send({ hitType: "pageview", page: "/contact" });

  return (
    <div className="footer-adjust">
      <Navbar />
      <header>
        <div className="width-container"></div>
      </header>
      <main>
        <div className="width-container">
          <div className="contact-email_box">
            <span className="contact-email">
              <strong>email: </strong>
            </span>
            <span className="contact-email"> contact@patcode.co.uk</span>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Contact;
