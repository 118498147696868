import ReactGA from "react-ga4";

import "./styles/AboutMe.scss";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import avatar from "../images/aboutMe/avatar.jpg";
import title from "../images/about_me_title.png";
import workspace from "../images/workspace.jpg";

const AboutMe = () => {
  ReactGA.send({ hitType: "pageview", page: "/about-me" });

  return (
    <div className="footer-adjust">
      <Navbar />
      <header className="about_me-header">
        <div className="width-container">
          <div className="about_me-left_side">
            <div className="about_me-profile_image_container">
              <img
                className="about_me-profile_image"
                src={avatar}
                alt="profile picture"
              ></img>
            </div>
          </div>
          <div className="about_me-right_side">
            <img
              className="about_me-title"
              src={title}
              alt="profile picture"
            ></img>
          </div>
        </div>
      </header>
      <main>
        <section className="about_me-section">
          <div className="width-container">
            <h2 className="section_title">Shortly About Me</h2>
            <span className="about_me-section_text">
              Since I was a child, I was fascinated with computers and wanted to
              become a hacker. Eventually my children's dream almost came true
              and I started creating computer games ... , but I am also creating
              websites (web applications) because I like it, and I am pretty
              sure I am doing it well.
              <br />
              <br />A few years ago I had a fight with an illness that made me
              realize how important our goals are in our life, and that we have
              a limited time to complete them. That's why I started to do what I
              really like in life.
              <br />
              <br />
              In the field of computer games, I specialize in artificial
              intelligence and environment interaction. I also have experience
              in creating shaders in C ++ on the PS4 (DevKit) platform and
              creating a user interface.
              <br />
              <br />
              When it comes to creating web applications, I am good at PHP which
              is mainly used for backend and creating databases using MySQL and
              phpMyAdmin. However, recently I had the pleasure of working with
              the React.js library, which significantly increases the
              performance and possibilities of the application (the page you are
              on was made with React).
              <br />
              <br />I personally like cooperation in an organized team and
              people who do it with passion. I am a motivated person and ready
              to work on new projects.
            </span>
          </div>
        </section>
        <section className="about_me-section">
          <div className="width-container">
            <h2 className="section_title">Programming Languages</h2>
            <span className="about_me-section_text">
              - C# (advanced)
              <br />- C++ (advanced)
              <br />- HTML
              <br />- Java Script (advanced)
              <br />- CSS (advanced)
              <br />- Sass
              <br />- PHP
              <br />- SQL
              <br />- Java
              <br />- C
            </span>
          </div>
        </section>
        <section className="about_me-section">
          <div className="width-container">
            <h2 className="section_title">
              Technolgies / Libraries / Frameworks
            </h2>
            <span className="about_me-section_text">
              - React.js (js library)
              <br />- Bootsrap (css framework)
              <br />- Node.js (opensource multiplatform)
              <br />- Java Script (advanced)
              <br />- Unity Engine (game engine)
              <br />- Unreal Engine (game engine)
              <br />- GitHub (source control solution)
              <br />- GitHub Desktop(source control application)
              <br />- Source Tree (source control application)
              <br />- Xcode (integrated development environment)
              <br />- Visual Studio Proffesional (integrated development
              environment)
              <br />- Visual Studio Code (code editor)
              <br />- Sublime Text (code editor)
              <br />- Notepad++ (code editor)
              <br />- FileZilla (FTP client)
              <br />- XAMPP (local Apache server, database MySQL)
              <br />- MAMP (local server)
              <br />- phpMyAdmin (software tool, handle MySQL database)
              <br />- Microsoft Excel (spreadsheet)
            </span>
          </div>
        </section>
        <section className="about_me-section">
          <div className="width-container">
            <h2 className="section_title">My Workspace</h2>
            <img
              className="about_me-workspace"
              src={workspace}
              alt="profile picture"
            ></img>
            <span className="about_me-section_text">
              The place where I work on projects every day. Contrary to
              appearances, I do not have a desktop PC. I am using a laptop
              equipped with a Thunderbolt port, to which I have connected the
              eGPU and other accessories. Thanks to this solution, I can work
              efficiently and effectively at home, and if necessary, I am able
              to take the project on which I worked myself, without the need to
              copy it to another disk or computer. I also have a graphic tablet
              on the equipment, which has undergone several projects to create
              an interface or photo processing
            </span>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default AboutMe;

/*
  useEffect(() => {
    return () => {};
  }, []);

              <h2 className="about_me-title">HELLO!</h2>
            <h3 className="about_me-title">I AM PATRICK</h3>
            <h4 className="about_me-title">
              I AM FULL-STACK WEB DEVELOPER & GAME DEVELOPER
            </h4>
*/
