import MovingComponent from "react-moving-text";
import ReactGA from "react-ga4";

import "./styles/Home.scss";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import csharp from "../images/logos/csharp.png";
import cpp from "../images/logos/cpp.png";
import css from "../images/logos/css.png";
import html from "../images/logos/html.png";
import js from "../images/logos/js.png";
import mysql from "../images/logos/mysql.png";
import php from "../images/logos/php.png";
import ps from "../images/logos/ps.png";
import unity from "../images/logos/unity.png";
import unreal from "../images/logos/unreal.png";
import vs from "../images/logos/vs.png";

const Home = () => {
  ReactGA.send({ hitType: "pageview", page: "/" });

  return (
    <div className="footer-adjust">
      <Navbar />
      <header className="home-header">
        <div className="home-container width-container">
          <MovingComponent
            className="home-header_titles"
            type="glowing"
            direction="normal"
            duration="1000ms"
            delay="2s"
            iteration="2"
          >
            PatCOde
          </MovingComponent>
          <MovingComponent
            className="home-header_titles"
            type="glowing"
            direction="normal"
            duration="2000ms"
            delay="7s"
            iteration="2"
          >
            My passion ...
          </MovingComponent>
          <MovingComponent
            className="home-header_titles"
            type="glowing"
            direction="normal"
            duration="1500ms"
            delay="5s"
            iteration="2"
          >
            ... CODING
          </MovingComponent>
        </div>
      </header>
      <main>
        <div className="width-container">
          <section className="home-main_section">
            <h2 className="home-section_title">
              {" "}
              Technology I specialize in ...
            </h2>
            <MovingComponent
              className="home-logos"
              type="blur"
              duration="1000ms"
              delay="10s"
              iteration="2"
            >
              <img className="home-logo_image" src={csharp} alt="logo" />
            </MovingComponent>
            <MovingComponent
              className="home-logos"
              type="blur"
              duration="1000ms"
              delay="11s"
              iteration="2"
            >
              <img className="home-logo_image" src={cpp} alt="logo" />
            </MovingComponent>
            <MovingComponent
              className="home-logos"
              type="blur"
              duration="1000ms"
              delay="12s"
              iteration="2"
            >
              <img className="home-logo_image" src={css} alt="logo" />
            </MovingComponent>
            <MovingComponent
              className="home-logos"
              type="blur"
              duration="1000ms"
              delay="13s"
              iteration="2"
            >
              <img className="home-logo_image" src={html} alt="logo" />
            </MovingComponent>
            <MovingComponent
              className="home-logos"
              type="blur"
              duration="1000ms"
              delay="14s"
              iteration="2"
            >
              <img className="home-logo_image" src={js} alt="logo" />
            </MovingComponent>
            <MovingComponent
              className="home-logos"
              type="blur"
              duration="1000ms"
              delay="15s"
              iteration="2"
            >
              <img className="home-logo_image" src={mysql} alt="logo" />
            </MovingComponent>
            <MovingComponent
              className="home-logos"
              type="blur"
              duration="1000ms"
              delay="16s"
              iteration="2"
            >
              <img className="home-logo_image" src={php} alt="logo" />
            </MovingComponent>
            <MovingComponent
              className="home-logos"
              type="blur"
              duration="1000ms"
              delay="17s"
              iteration="2"
            >
              <img className="home-logo_image" src={ps} alt="logo" />
            </MovingComponent>
            <MovingComponent
              className="home-logos"
              type="blur"
              duration="1000ms"
              delay="18s"
              iteration="2"
            >
              <img className="home-logo_image" src={unity} alt="logo" />
            </MovingComponent>
            <MovingComponent
              className="home-logos"
              type="blur"
              duration="1000ms"
              delay="19s"
              iteration="2"
            >
              <img className="home-logo_image" src={unreal} alt="logo" />
            </MovingComponent>
            <MovingComponent
              className="home-logos"
              type="blur"
              duration="1000ms"
              delay="20s"
              iteration="2"
            >
              <img className="home-logo_image" src={vs} alt="logo" />
            </MovingComponent>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Home;
