import { Route, useRouteMatch } from "react-router-dom";
import ReactGA from "react-ga4";

import { useState, useEffect } from "react";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import PortfolioList from "../sections/PortfolioList";
import PortfolioDetails from "../sections/PortfolioDetails";

import getUniversityProjectsData from "../data/UniversityProjectsData";
import getGamePracticalData from "../data/GamePracticalData";
import getOldschoolProjectsData from "../data/OldschoolProjectsData";

const Portfolio = () => {
  ReactGA.send({ hitType: "pageview", page: "/portfolio" });

  const match = useRouteMatch();

  const universityProjectsData = getUniversityProjectsData();
  const gamePracticalProjectsData = getGamePracticalData();
  const oldschoolProjectsData = getOldschoolProjectsData();

  const [projectsData, setProjectData] = useState(universityProjectsData);

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className="footer-adjust">
      <Navbar />
      <header className="portfolio-header">
        <div className="width-container"></div>
      </header>
      <main>
        <div className="width-container">
          <Route exact path="/portfolio">
            <PortfolioList
              universityProjectsData={universityProjectsData}
              gamePracticalData={gamePracticalProjectsData}
              oldschoolProjectsData={oldschoolProjectsData}
            />
          </Route>
          <Route
            path={`${match.path}/project-details-:projectType-:projectIndex`}
          >
            <PortfolioDetails />
          </Route>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Portfolio;

/*
          <Route exact path="/portfolio/project-details">

*/
