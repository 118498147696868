import { NavLink } from "react-router-dom";

import classes from "./styles/Navbar.scss";

import navLogo from "../images/nav_logo.png";

const Navbar = () => {
  var rotated = false;

  function showNavigation() {
    var x = document.getElementById("nav-list");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
    animateBurger();
  }

  function animateBurger() {
    var bar1 = document.getElementById("nav-burger_bar_1"),
      deg = rotated ? 0 : 45,
      opacity = rotated ? 1 : 0,
      mov = rotated ? 0 : 13;
    var bar2 = document.getElementById("nav-burger_bar_2");
    var bar3 = document.getElementById("nav-burger_bar_3");
    bar1.style.transform = " translate(0px, " + mov + "px)";
    bar1.style.transform += "rotate(" + deg + "deg)";
    bar2.style.opacity = opacity;
    bar3.style.transform = " translate(0px, " + -mov + "px)";
    bar3.style.transform += "rotate(" + -deg + "deg)";
    rotated = !rotated;
  }

  return (
    <nav className="nav">
      <div className="width-container">
        <NavLink exact to="/" activeClassName={classes.active}>
          <img className="nav-logo" src={navLogo} alt="logo" />
        </NavLink>
        <ul id="nav-list">
          <li className="nav-list_item">
            <NavLink
              className="nav-list_navlink"
              exact
              to="/"
              activeClassName={classes.active}
            >
              HOME
            </NavLink>
          </li>
          <li className="nav-list_item">
            <NavLink
              className="nav-list_navlink"
              exact
              to="/about-me"
              activeClassName={classes.active}
            >
              ABOUT ME
            </NavLink>
          </li>
          <li className="nav-list_item">
            <NavLink
              className="nav-list_navlink"
              to="/portfolio"
              activeClassName={classes.active}
            >
              PORTFOLIO
            </NavLink>
          </li>
          <li className="nav-list_item">
            <NavLink
              className="nav-list_navlink"
              to="/contact"
              activeClassName={classes.active}
            >
              CONTACT
            </NavLink>
          </li>
        </ul>
        <div id="nav-burger" onClick={showNavigation}>
          <div id="nav-burger_bar_1"></div>
          <div id="nav-burger_bar_2"></div>
          <div id="nav-burger_bar_3"></div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
