import { BrowserRouter as Router, Route } from "react-router-dom";
import ReactGA from "react-ga4";

import "./App.css";

import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";
import AboutMe from "./pages/AboutMe";

function App() {
  const TRACKING_ID = "G-KXHM7GKFPK";
  ReactGA.initialize(TRACKING_ID);

  return (
    <div className="App">
      <Router>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/about-me">
          <AboutMe />
        </Route>
        <Route path="/portfolio">
          <Portfolio />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
      </Router>
    </div>
  );
}

export default App;
