import { NavLink } from "react-router-dom";

import "./styles/Footer.scss";

import logo from "../images/logo.png";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="width-container">
        <img className="footer-logo" src={logo} alt="logo" />
        <a className="footer-email" href="mailto: contact@patcode.co.uk">
          contact@patcode.co.uk
        </a>
        <p className="footer-copyright">&copy; Copyright 2022 Pat Code</p>
      </div>
    </footer>
  );
};

export default Footer;
