import "./styles/PortfolioList.scss";

import ShowcaseBox from "../components/ShowcaseBox";

const ProjectType = {
  University: 0,
  Practical: 1,
  Freelance: 2,
  Oldschool: 3,
};

const PortfolioList = (props) => {
  const universityProjectsData = props.universityProjectsData;
  const gamePracticalProjectsData = props.gamePracticalData;
  const oldschoolProjectsData = props.oldschoolProjectsData;

  const universityProjectsList = universityProjectsData.map(
    (project, index) => (
      <ShowcaseBox
        key={index}
        index={index}
        projectData={project}
        type={ProjectType.University}
      />
    )
  );

  const gamePracticalProjectsList = gamePracticalProjectsData.map(
    (project, index) => (
      <ShowcaseBox
        key={index + 10}
        index={index}
        projectData={project}
        type={ProjectType.Practical}
      />
    )
  );

  const oldschoolProjectsList = oldschoolProjectsData.map((project, index) => (
    <ShowcaseBox
      key={index + 10}
      index={index}
      projectData={project}
      type={ProjectType.Oldschool}
    />
  ));

  return (
    <>
      <section className="portfolio-section">
        <h2 className="section_title">University projects ...</h2>
        <div>{universityProjectsList}</div>
      </section>
      <section className="portfolio-section">
        <h2 className="section_title">Game practical projects ...</h2>
        <div>{gamePracticalProjectsList}</div>
      </section>
      <section className="portfolio-section">
        <h2 className="section_title">Oldschool projects ...</h2>
        <div>{oldschoolProjectsList}</div>
      </section>
    </>
  );
};
export default PortfolioList;

/*
      <section className="portfolio-section">
        <h2 className="section_title">Freelance projects ...</h2>
      </section>
*/
