import { Link } from "react-router-dom";

import "./styles/Button.scss";

const Button = (props) => {
  return (
    <Link
      style={{ width: props.width, height: props.height }}
      className={"button-container" + " " + props.className}
      to={props.url}
    >
      <button className="button">{props.text}</button>
    </Link>
  );
};

export default Button;
