import { useState, useEffect } from "react";
import { useParams } from "react-router";
import ReactPlayer from "react-player";

import "./styles/PortfolioDetails.scss";

import Button from "../components/Button";

import getUniversityProjectsData from "../data/UniversityProjectsData";
import getGamePracticalData from "../data/GamePracticalData";
import getOldschoolProjectsData from "../data/OldschoolProjectsData";

const PortfolioDetails = (props) => {
  const params = useParams();
  const projectType = parseInt(params.projectType)
    ? parseInt(params.projectType)
    : 0;
  const projectIndex = parseInt(params.projectIndex)
    ? parseInt(params.projectIndex)
    : 0;

  const [projectsData, setProjectData] = useState(getGamePracticalData());

  const [width, setWidth] = useState(window.innerWidth);
  const [playerWidth, setPlayerSize] = useState(994);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    handleWindowSizeChange();

    switch (projectType) {
      case 0:
        setProjectData(getUniversityProjectsData());
        break;
      case 1:
        setProjectData(getGamePracticalData());
        break;
      case 2:
        setProjectData();
        break;
      case 3:
        setProjectData(getOldschoolProjectsData());
        break;
      default:
    }
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
      handleWindowSizeChange();
    };
  }, []);

  return (
    <section className="portfolio-section">
      <h2 className="section_title">
        {projectsData[projectIndex].title
          ? projectsData[projectIndex].title
          : "there is no title"}
      </h2>
      <div className="portfolio_details-panel">
        <span className="portfolio_details-short_description">
          {projectsData[projectIndex].shortDescription}
        </span>
        <ReactPlayer
          className="portfolio_details-player"
          url={projectsData[projectIndex].url}
          playing={false}
          controls={true}
          width={playerWidth}
          height="400"
        />
        <div className="portfolio_details-buttons">
          <p className="portfolio_details-description_title">Description</p>
          <Button
            className="portfolio_details-button"
            text={"< BACK"}
            url={"/portfolio"}
          />
        </div>
        <p className="portfolio_details-long_description">
          {projectsData[projectIndex].description}
        </p>
      </div>
    </section>
  );
};

export default PortfolioDetails;
